// component import will be like this only:
    // 1. REACT import (always first)
    // 2. node_module DEPENDENCIES,
    // 3. SUB_MODULES,
    // 4. CUSTOM components,
    // 5. LOCAL_STORAGE functions,
    // 6. REDUX functions,
    // 7. exported VARIABLES (always last)

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import { logEvent } from "firebase/analytics";
import { startOfDay } from "date-fns";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faArrowsRotate, faArrowLeftLong  } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import { Deck } from "../cards/deck.js";
import { analytics } from '../firebase_setup/firebase';
import NavModal from "./NavModal";
import NavBar from "./NavBar";
import Tricks from "../cards/tricks.js";
import gameJSON from "../constants/game.json";
import tutorialProblems from "../constants/tutorialProblems.json";
import practiceProblems from "../constants/practiceProblems.json";
import realProblems from '../constants/problems.json'
import Target from "./Grid/Target";
import HandGrid from "./Grid/HandGrid";
import Trick from "./Grid/TrickArray";
import SideGrid from "./Grid/SideGrid";
import Walkthrough from "./Walkthrough";
import Alert from "./Alert";
import PracticeCardle from "./Grid/PracticeCardle";

import { shareStatus } from "../lib/share";
import { saveStats } from "../lib/stats";
import useAlert from "./hooks/useAlert";
import useHandlePlay from "./hooks/useHandlePlay";
import {
  setLocalStorageValue,
  getLocalState,
  getLocalGameState,
  clearLocalGameState,
  removeLocalState,
} from "../lib/localStorage";
import {
  SET_HANDS,
  SET_TRICKS,
  SET_TARGET,
  SET_SCORE,
  SET_GAME_WON,
  SET_GAME_LOST,
  UPDATE_ATTEMPTS,
  SET_CURRENT_TRICK,
  SET_IS_PLAYED,
  ADD_WINNERS,
  UNDO_CARD,
  SET_CURRENT_TURN,
  SET_TOTAL_SCORE,
  RESET_WINNERS,
  REMOVE_PLAYED_CARDS,
  CHANGE_CARD_COUNT,
  CHANGE_PLAYER_COUNT,
  SET_ALERT_MESSAGE,
  GAME_LOCK,
  GAME_UNLOCK,
  SET_FOLLOWSUIT,
  SET_VISITED_TRUMP,
  SET_PREV_TRICK,
  UPDATE_TARGET,
  SET_DEVICE_SIZE,
  LOAD_DATA,
  DISABLE_GUIDE,
  SET_SAVE_STATS,
  SET_INTRO_FLAG,
  ENABLE_GUIDE,
  SET_TRUMP_COLOR,
  SET_GAME_INDEX,
  SET_BALL_COUNT,
  SET_ANIMATION,
  SET_ANIMATED_TARGET,
  SET_FADE_IN,
  SET_INFINITE_ANIMATION,
  SET_INFINITE_ANIMATED_TARGET,
  SET_STOP_ANIMATION,
  SET_GAME_PRACTICE,
  SHOW_PROBLEMS,
  SET_LANG_FONT_SIZE,
  SET_PRACTICE_SELECTION,
  SET_SOLVED_PROBLEMS,
  SET_CARD_SIZE,
  SET_ALERT_STOP
} from "../redux/reducers/gameSlice";
import { userID } from "../App";

let tricks;
let deck;
let allAttempts = 5;

export const followSuit = new Map([
  ["R", { text: "red", color: "#DC4C64" }],
  ["G", { text: "green", color: " #14A44D" }],
  ["B", { text: "blue", color: "#3b71ca" }],
  ["Y", { text: "yellow", color: "#E4A11B" }],
  ["T", { text: "black&white", color: "orange" }],
]);

let trump, ourTrumpColor;
let tomorrow;

const success = "success";
const error = "error";
let cardSize;
const url = "../constants/realProblems.json";

export function pad(n, length) {
  var len = length - ("" + n).length;
  return (len > 0 ? new Array(++len).join("0") : "") + n;
}

const Home = () => {
    // window.screen.orientation.lock('portrait');
    const state = useSelector((state) => state);
    const isGuideNeed = state.game.isGuideNeed;
    const gamePractice = state.game.gamePractice;
    const showProblems = state.game.showProblems;
    const helpIntroFlag = state.game.helpIntroFlag;
    const deviceSize = state.game.deviceSize;
    const index = state.game.gameIndex;
    const gameLost = state.game.gameLost;
    const gameWon = state.game.gameWon;
    const isDarkMode = state.game.isDarkMode;
    const attempts = state.game.attempts;
    const fullTricks = state.game.tricks;
    const languages = state.game.language;
    const langFont = state.game.langFont;
    console.log("langFont code ", langFont, deviceSize)
    const alertStop = state.game.alertStop;

    const dispatch = useDispatch();
    const { setAlertMessage } = useAlert();
    const { cardPlay } = useHandlePlay();

    const { t } = useTranslation();
    const language = Object.values(languages)[0];

    const langFontSize = {
      tutorial: {
        fontSize:
          language == "ENGLISH"
            ? "var(--font-english)"
            : language == "KOREAN"
            ? "var(--intro-korean)"
            : language == "TELUGU"
            ? "var(--tuto-telugu)"
            : language == "ODIYA"
            ? "var(--tuto-odiya)"
            : language == "HINDI"
            ? "var(--font-hindi)"
            : language == "TAMIL"
            ? deviceSize < 550
              ? "var(--mb-tuto-tamil)"
              : "var(--font-tamil)"
            : "var(--english-tuto)",
      },
      practice: {
        fontSize:
          language == "ENGLISH"
            ? "var(--font-english)"
            : language == "TAMIL"
            ? "var(--font-tamil)"
            : "var(--english-tuto)",
      },
      buttons: {
        fontSize: language
          ? language == "ENGLISH"
            ? "var(--quick-modal-button)"
            : language == "TAMIL"
            ? deviceSize < 550
              ? "var(--mb-quick-tamil)"
              : "var(--dk-quick-tamil)"
            : "var(--english-tuto)"
          : "",
      },
      stats: {
        fontSize:
          deviceSize < 550
            ? language == "ENGLISH"
              ? "var(--stats-squares)"
              : language == "TAMIL"
              ? "var(--mb-stats-tamil)"
              : ""
            : language == "ENGLISH"
            ? "var(--stats-squares)"
            : language == "TAMIL"
            ? "var(--dk-stats-tamil)"
            : "var(--stats-squares)",
      },
      containerButton: {
        fontSize: language
          ? language == "ENGLISH"
            ? "var(--dk-container-english)"
            : language == "TAMIL"
            ? deviceSize < 550
              ? "var(--mb-container-tamil)"
              : "var(--dk-container-tamil)"
            : "var(--english-tuto)"
          : "",
      },
      alert: {
        fontSize: language
          ? language == "ENGLISH"
            ? "var(--alert-english)"
            : language == "TAMIL"
            ? deviceSize < 550
              ? "var(--mb-alert-tamil)"
              : "var(--dk-alert-tamil)"
            : "var(--english-tuto)"
          : "",
      },
      tutorialButtons: {
        fontSize: language
          ? language == "ENGLISH"
            ? "var(--dk-button-tuto)"
            : language == "TELUGU"
            ? "var(--tuto-telugu)"
            : language == "ODIYA"
            ? "var(--tuto-odiya)"
            : language == "TAMIL"
            ? deviceSize < 550
              ? "var(--mb-button-tuto)"
              : "var(--dk-button-tuto)"
            : "var(--english-tuto)"
          : "",
      },
    };

    const realGameEvents = (decide, val, level) => {
      if(decide == "start") {
        logEvent(analytics, 'todays_problem_start', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          edition: val,
          level: level
        });
      } else if(decide == "mid") {
        logEvent(analytics, 'todays_trick_state', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          trick_num: val,
          // trick_status: 
        });
      } else {
        logEvent(analytics, 'todays_problem_end', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          end_type: val
        });
      }
    }

    const practiceEvents = (decide, val, index, level) => {
      if(decide == "start") {
        logEvent(analytics, 'practice_problem_selected', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          level: level,
          problem_num: index
        });
      } else if (decide == "mid") {
        logEvent(analytics, 'practice_trick_complete', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          trick_num: val,
          // trick_status: 
        });
      } else {
        logEvent(analytics, 'practice_problem_end', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          end_type: val
        });
      }
    }
/**
 * 
 * @param {*} category 
 * @param {*} name 
 * @returns 
 */
    const buttonClick = (category, name) => {
      if(category == "intro" && name == "close") {
        realGameEvents("start", dayIndex+1, "easy")
      }
      if(category == "navbar") {
        logEvent(analytics, 'button_clicked', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          category: category,
          button: name
        });
      } else if (category == "practice") {
        logEvent(analytics, 'button_clicked', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          category: category,
          button: name
        });
      } else if (category == "tutorial") {
        if(name == "enter" || name == "undo") {
          return
        }
        logEvent(analytics, 'button_clicked', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          category: category,
          button: name
        });
      } else if (category == "real_game") {
        logEvent(analytics, 'button_clicked', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          category: category,
          button: name
        });
      } else if (category == "intro") {
        logEvent(analytics, 'button_clicked', {
          event_id: uuidv4(), 
          event_timestamp: Date.now(), 
          user_id: userID,
          session_id: uuidv4(),
          category: category,
          button: name
        });
      } else {
        console.log("no se presiona nada, no es posible")
      }
    }
    
  const practiceReset = {
    border: "1px solid #3d3d3d",
    width: "calc(var(--card-size) * .7)",
    height: "calc(var(--card-size) * .7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    borderColor: isDarkMode ? "#ffffff" : "#3d3d3d",
  };

  // For tutorial modal
  const [showTutoModal, setShowTutoModal] = useState(
    getLocalState("tutorial") === null ? true : !getLocalState("tutorial")
  );
  const [isHandHovered, setHandHovered] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isHintModalOpen, setIsHintModalOpen] = useState(false);
  const [showTargetModal, setShowTargetModal] = useState(false);
  const [isFailureContainerOpen, setFailureContainerOpen] = useState(false);
  const [isSolutionModalOpen, setIsSolutionModalOpen] = useState(false);
  const [isGamePractice, setIsGamePractice] = useState(false);

  const [gameLoaded, setGameLoaded] = useState(true);
  //const [index,setIndex] = useState(0)

  const [fetchProblem, setFetchProblem] = useState(true);
  const [response, setResponse] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [dayIndex, setDayIndex] = useState(null);
  const [todaysProblem, setTodaysProblem] = useState(null);

  const [trickArray, setTrickArray] = useState(["", "", ""]);
  const [hardMode, setHardMode] = useState(true);

  //check whether it is a touch screen or not

  let isTouchEnabled =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

    const main_cardoo = "calc(min(calc((100vw - var(--card-gap) * 12) / (2 + 3)), calc((calc((100vh - 60px - 30vh - calc(var(--card-gap) * 4)) / 8)))) - 9px)"
    const mob_tutorial_cardoo = "calc(min(calc((100vw - var(--card-gap) * 12) / (3 + 1)), calc((calc((100vh - 60px - 30vh  - calc(var(--card-gap) * 4)) / 9)))) - 9px)"
    const mob_cardoo = "calc(min(calc((100vw - var(--card-gap) * 12) / (3 + 2)), calc((calc((100vh - 60px - 30vh  - calc(var(--card-gap) * 4)) / 8)))) - 10px)"
    cardSize = deviceSize < 550 ? isGuideNeed ? mob_tutorial_cardoo : mob_cardoo : main_cardoo;

  // const card1 = "calc(min(calc((100vw - var(--card-gap) * 10) / (2 + 3)), calc((calc((100vh - 60px - 30vh) / 8)))) - 10px)"
  // const card2 = "calc(min(calc((100vw - var(--card-gap) * 10) / (3 + 1)), calc((calc((100vh - 60px - 30vh) / 9)))) - 9px)"
  // cardSize = isGuideNeed && deviceSize < 550 ? card2 : card1;
  dispatch(SET_CARD_SIZE(cardSize))

  let getDeviceSize = window.innerWidth;

  const getNextDate = (today) => {
    const nalai = startOfDay(today);
    tomorrow = nalai.setDate(nalai.getDate() + 1); //new Date(momo)
    return tomorrow;
  };

   // once the data's are fetched
    useEffect(() => {
      if (fetchProblem) {
        setFetchProblem(false);
        getNextDate(new Date());
        // the today value will be 0 to 6 , 0 for sunday and 6 for saturday
        let today = new Date();
        let startingDate = new Date(gameJSON.startingDate);

        let dateDiff = dayjs(today).diff(dayjs(startingDate), "day");

        if (dateDiff % 630 === 0) dateDiff = dateDiff - 630;

        setDayIndex(dateDiff);

        // setting the todays problem
        if (realProblems[dateDiff]) setTodaysProblem(realProblems[dateDiff]);

        setGameLoaded(false);
      }
    }, [fetchProblem]);

    useEffect(() => {
      dispatch(SET_LANG_FONT_SIZE(langFontSize))
      // dispatch(CHANGE_LANG(userLang))
    },[languages])

    useEffect(() => {
    const startGame = () => {
      setGameLoaded(true);
      if(!isGuideNeed && !gamePractice) {
        realGameEvents("start", dayIndex + 1, "easy")
      }
      dispatch(SET_DEVICE_SIZE(getDeviceSize));

      let data = {};
      let saveStats;

      // initial animation
      data.fadeIn = false;
      data.isAnimationNeed = false;
      data.infiniteAnimation = false;
      data.stopAnimation = true;
      data.followSuit = "";
      data.isAnimationComplete = true;

      setLocalStorageValue("solvedProblems", state.game.solvedPracticeProblems);

      // This is for only practice mode :
      // We won't save stats and unlimited attempts also
      if (gamePractice) {
        data.hands = practiceProblems[showProblems].hands;
        data.target = practiceProblems[showProblems].target;
        data.columnHint = practiceProblems[showProblems].columnhint;
        data.animatedTarget = practiceProblems[showProblems].target;
        data.infiniteAnimatedTarget = practiceProblems[showProblems].target;
        data.cardCount = practiceProblems[showProblems].hands[0].length;
        data.playerCount = practiceProblems[showProblems].hands.length;
        data.ballCount = practiceProblems[showProblems].hands[0].length;

        // initial stage
        data.tricks = [new Array(data.playerCount).fill("")];
        data.gameLost = false;
        data.gameWon = false;
        data.attempts = 10;
        data.totalScore = [];
        data.score = new Array(data.playerCount).fill(0);
        data.followSuit = "";
        data.walkthroughStep = 1;

        // setting the saveStats to false because it's tutorial game
        saveStats = false;

        // setting the trump Color
        trump = practiceProblems[showProblems].trump;
        ourTrumpColor = trump ? state.game.suitColor.get(trump) : "";
        dispatch(SET_TRUMP_COLOR(ourTrumpColor));
      } else if (isGuideNeed) {
        data.hands = tutorialProblems[index].hands;
        data.target = tutorialProblems[index].target;
        data.columnHint = tutorialProblems[index].columnhint;
        data.animatedTarget = tutorialProblems[index].target;
        data.infiniteAnimatedTarget = tutorialProblems[index].target;
        data.cardCount = tutorialProblems[index].hands[0].length;
        data.playerCount = tutorialProblems[index].hands.length;
        data.ballCount = tutorialProblems[index].hands[0].length;

        // initial stage
        data.tricks = [new Array(data.playerCount).fill("")];
        data.gameLost = false;
        data.gameWon = false;
        data.attempts = 5;
        data.totalScore = [];
        data.score = new Array(data.playerCount).fill(0);
        data.followSuit = "";
        data.walkthroughStep = 1;

        // setting the saveStats to false because it's tutorial game
        saveStats = false;

        // setting the trump Color
        trump = tutorialProblems[index].trump;
        ourTrumpColor = trump ? state.game.suitColor.get(trump) : "";
        dispatch(SET_TRUMP_COLOR(ourTrumpColor));
      } else {
        // else it's real game
        // check the localStorage for gameStates
        let gameState = getLocalGameState(); // it returns a Map Object
        // console.log("game state ", gameState);
        // TODO remove this code
        // to clear the old game stats because now we are loading new problems
        let isNewProblem = getLocalState("isNewProblem");

        if (!isNewProblem) {
          setLocalStorageValue("isNewProblem", true);
          clearLocalGameState();
          removeLocalState("gamestats");
          removeLocalState("day");
        }

        let lastLogin = getLocalState("lastlogin");
        if (!lastLogin) {
          lastLogin = new Date();
          setLocalStorageValue("lastlogin", lastLogin);
        }
        // the date diff between last login date and current date should be less than or equal to 7
        let dateDiff = dayjs(new Date()).diff(dayjs(lastLogin), "day");

        setLocalStorageValue("lastlogin", new Date());

        // setting the trump Color
        trump = todaysProblem?.trump;
        ourTrumpColor = trump ? state.game.suitColor.get(trump) : "";
        dispatch(SET_TRUMP_COLOR(ourTrumpColor));

        // setting the saveStats to true because it's real game
        // if the gamestate already stored and day must be today's date
        if (
          gameState.size === 9 &&
          getLocalState("day") === dayIndex 
        ) {
          // set hardmode
          if (getLocalState("hardmode") === false) setHardMode(false);

          let hands = gameState.get("hands");
          if (hands) data.hands = hands;

          if (gameState.get("tricks")) data.tricks = gameState.get("tricks");

          // cardCount and playerCount
          data.playerCount = todaysProblem.hands.length;
          data.cardCount = todaysProblem.hands[0].length;
          data.columnHint = todaysProblem.columnhint;

          if (gameState.get("target")) {
            data.target = gameState.get("target");
            data.animatedTarget = data.target;
            data.infiniteAnimatedTarget = data.target;
          }

          if (gameState.get("score")) data.score = gameState.get("score");

          if (gameState.get("totalscore"))
            data.totalScore = gameState.get("totalscore");

          if (typeof gameState.get("attempts") == "number")
            data.attempts = gameState.get("attempts");

          if (typeof gameState.get("gamewon") == "boolean")
            data.gameWon = gameState.get("gamewon");

          if (typeof gameState.get("gamelost") == "boolean")
            data.gameLost = gameState.get("gamelost");

          if (gameState.get("walkthroughstep"))
            data.walkthroughStep = gameState.get("walkthroughstep");

          // calculating the ball count for the target
          if ((data.tricks && data.gameLost) || data.gameWon) {
            if (
              data.tricks.length === 1 &&
              data.tricks[0].every((playedCard) => playedCard == "")
            ) {
              data.ballCount = data.  cardCount;
            } else {
              data.ballCount = data.cardCount - data.tricks.length;
            }
          } else {
            if (data.tricks) {
              if (data.tricks.length === 1) {
                data.ballCount = data.cardCount;
              } else {
                data.ballCount = data.cardCount - (data.tricks.length - 1);
              }
            }
          }

          if (data.gameLost || data.gameWon) setFailureContainerOpen(true);
        } else {
          // clear the old gameStates
          clearLocalGameState();
          // clear old image file
          removeLocalState("shareimage");
          setLocalStorageValue("hardmode", true);

          // set the today's day to local storage
          setLocalStorageValue("day", dayIndex);

          // current indexed problem should be defined
          if (todaysProblem) {
            data.hands = todaysProblem.hands;
            setLocalStorageValue("hands", data.hands);

            data.target = todaysProblem.target;
            setLocalStorageValue("target", data.target);
            data.animatedTarget = todaysProblem.target;
            data.infiniteAnimatedTarget = todaysProblem.target;

            data.cardCount = todaysProblem.hands[0].length;
            data.playerCount = todaysProblem.hands.length;
            data.ballCount = todaysProblem.hands[0].length;

            if (todaysProblem?.columnhint)
              data.columnHint = todaysProblem.columnhint;

            data.tricks = [new Array(data.playerCount).fill("")];
            data.gameLost = false;
            data.gameWon = false;
            data.attempts = 5;
            data.totalScore = [];
            data.score = new Array(data.playerCount).fill(0);
            data.followSuit = "";
          }
        }
        // setting the saveStats to false because it's tutorial game
          saveStats = true;
      }

        // and actually load the game
        loadGameToState(data, saveStats);
      };

      if (!gameLoaded) startGame();
    }, [
      dispatch,
      isGuideNeed,
      gameLoaded,
      dayIndex,
      index,
      todaysProblem,
      state.game.gamePractice,
      showProblems,
      languages
    ]);

    // once today's problem has been loaded to local state
    useEffect(() => {
      if (todaysProblem) {
        // setting the trump
        trump = todaysProblem.trump;
        // if the current problem is trump means
        // we have to set the index to tutorial problmes first trump problemc
        if (trump) {
          dispatch(SET_VISITED_TRUMP(true));
          setLocalStorageValue("istrumpvisited", true);
        } else {
          dispatch(SET_VISITED_TRUMP(false));
          setLocalStorageValue("istrumpvisited", false);
        }
      }
    }, [todaysProblem]);

  // to save current problem to redux state and local state
  const loadGameToState = (data, saveStats) => {
    // make the deck
    deck = new Deck(gameJSON.ranks, gameJSON.suits);

    // setting up the tricks
    tricks = new Tricks(deck);
    tricks.trump.setTrump = "T";
    tricks.maxLength.setMaxLength = data?.playerCount ? data.playerCount : null;

    // if the tricks already there means
    if (data.tricks) {
      // setting the currentTrick
      data.currentTrick = data.tricks.length;

      // to set the current trickArray
      setTrickArray(data.tricks[data.tricks.length - 1]);

      // to load the tricks into cards Tricks
      // first, we need to remove the empty cards
      let toLoadTricks = [...data.tricks];

      // to filter only valid cards
      let lastTrick = [];
      toLoadTricks[toLoadTricks.length - 1].forEach((card) => {
        if (card !== "") {
          lastTrick.push(card);
        }
      });
      toLoadTricks[toLoadTricks.length - 1] = lastTrick;

      // to set the followSuit value
      if (!!lastTrick[0]) data.followSuit = lastTrick[0].card.suit;

      let sortedTricks = [];
      toLoadTricks.forEach((trick) => {
        let beforeSort = [...trick];
        beforeSort.sort((p1, p2) => {
          if (p1.turnOrder > p2.turnOrder) return 1;
          else if (p1.turnOrder < p2.turnOrder) return -1;
          else return 0;
        });
        sortedTricks.push(beforeSort);
      });
      toLoadTricks = sortedTricks;
      // actual loading of the trick
      tricks.loadArray(toLoadTricks);

      // for calculating the winners array
      data.winners = [];
      if (
        data.tricks.length === 1 &&
        data.tricks[0].every((card) => card === "")
      ) {
        data.winners = [];
      } else if (data.gameLost || data.gameWon) {
        toLoadTricks.forEach((_, index) => {
          // including the last trick
          data.winners.push(tricks.getWinningCard(index + 1)); // trick based on 1
        });
      } else {
        toLoadTricks.forEach((_, index) => {
          // excluding the last trick
          if (index + 1 === toLoadTricks.length) return;
          data.winners.push(tricks.getWinningCard(index + 1)); // trick based on 1
        });
      }
    }
    if (saveStats) dispatch(SET_SAVE_STATS(true));
    else dispatch(SET_SAVE_STATS(false));

    // store it in state
    dispatch(LOAD_DATA(data));
  };

  // change to the next problem
  const nextTutorialProblem = () => {
    setFailureContainerOpen(false);
    reset();
    // if the current tutorial problem is last problem means set it to zero
    if (index === tutorialProblems.length - 1) {
      //setIndex(0)
      dispatch(SET_GAME_INDEX(0));
    } else {
      //setIndex(index + 1)
      dispatch(SET_GAME_INDEX(index + 1));
    }

    // reset the failure container to false
    if (isFailureContainerOpen) {
      setFailureContainerOpen(false);
    }

    // to load again the game data
    setGameLoaded(false);
    dispatch(GAME_LOCK());
  };

  // For practice cardle problemas:
  const practiceCardleProblems = (ind) => {
    // to stop ongoing animation
    dispatch(SET_STOP_ANIMATION(true));
    dispatch(SET_ANIMATION(false));
    dispatch(GAME_UNLOCK());

    dispatch(SHOW_PROBLEMS(ind));
    // reset the failure container to false
    dispatch(SET_PRACTICE_SELECTION(false));

    if (isFailureContainerOpen) {
      setFailureContainerOpen(false);
    }

    // to load again the game data
    setGameLoaded(false);
  };

  // change to the next problem
  const prevTutorialProblem = () => {
    setFailureContainerOpen(false);
    reset();
    // if the current tutorial problem is first problem means set it to last problem
    if (index === 0) {
      //setIndex(tutorialProblems.length - 1)
      dispatch(SET_GAME_INDEX(tutorialProblems.length - 1));
    } else {
      //setIndex(index + 1)
      dispatch(SET_GAME_INDEX(index - 1));
    }

    // to load again the game data
    setGameLoaded(false);
    dispatch(GAME_LOCK());
  };

  // change from tutuorial problem to today's cardle ;)
  const loadTodaysCardle = () => {
    // if the tutuorial game is lost or won , we have to reset
    if (state.game.gameLost) {
      dispatch(SET_GAME_LOST(false));
    } else if (state.game.gameWon) {
      dispatch(SET_GAME_WON(true));
    }

    if (isFailureContainerOpen) {
      setFailureContainerOpen(false);
    }

    // if animation set to true , remove animation
    if (state.game.isAnimationNeed) {
      dispatch(SET_ANIMATION(false));
    }

    // set guide needed flag to false
    dispatch(DISABLE_GUIDE());

    // to load again the game data
    setGameLoaded(false);
  };

  // saving the stats
  useEffect(() => {
    if (state.game.saveStats) {
      setLocalStorageValue("hands", state.game.hands);
      setLocalStorageValue("tricks", state.game.tricks);
      setLocalStorageValue("gamewon", state.game.gameWon);
      setLocalStorageValue("gamelost", state.game.gameLost);
      setLocalStorageValue("attempts", state.game.attempts);
      setLocalStorageValue("target", state.game.target);
      setLocalStorageValue("score", state.game.score);
      setLocalStorageValue("totalscore", state.game.totalScore);
      setLocalStorageValue("walkthroughstep", state.game.walkthroughStep);
      setLocalStorageValue("solvedProblems", state.game.solvedPracticeProblems);
    }
  }, [
    state.game.saveStats,
    state.game.totalScore,
    state.game.hands,
    state.game.tricks,
    state.game.attempts,
    state.game.gameWon,
    state.game.gameLost,
    state.game.target,
    state.game.walkthroughStep,
    state.game.score,
    state.game.solvedPracticeProblems,
  ]);

  useEffect(() => {
    if (state.game.attempts == 0) {
      //setIsShareModalOpen(true);
      dispatch(GAME_LOCK());
      setFailureContainerOpen(true);
    }
    if (state.game.gameWon || state.game.gameLost) {
      setFailureContainerOpen(true);
    }
    if (state.game.gameWon && state.game.gamePractice) {
      // console.log("solved ||| set to state", state.game.showProblems);
      dispatch(SET_SOLVED_PROBLEMS(state.game.showProblems));
    }
  }, [state.game.attempts, state.game.gameWon, state.game.gameLost]);
  useEffect(()=>{
          setLocalStorageValue(
            "solvedProblems",
            state.game.solvedPracticeProblems
          );
  },[state.game.solvedPracticeProblems])
  // console.log("solved ||| solvedProblems", state.game.solvedPracticeProblems);
  useEffect(() => {
    if (!index) {
      //setFirstProblemCounter(firstProblemCounter+1)
    }
  }, [index]);

  //opening failure container
  useEffect(() => {
    if (state.game.target.length > 0) {
      if (state.game.target.find((t) => t === "-1")) {
        dispatch(GAME_LOCK());
        setFailureContainerOpen(true);
      }
    }
  }, [state.game.target]);

  const onPlay = (card, player) => {
    if (state.game.gameWon || state.game.gameLost) {
      dispatch(SET_ALERT_MESSAGE("Game Over..."));
      return null;
    }
    tricks = cardPlay(tricks, trickArray, card, player, setTrickArray);
  };

  const enter = () => {
    let target;
    if (isGuideNeed) {
      // if it's tutorial game
      target = tutorialProblems[index].target;
    } else if (gamePractice) {
      // if it's a practice cardle
      target = practiceProblems[showProblems].target;
    } else {
      // if it's real game
      target = todaysProblem.target;
    }

    if (state.game.gameWon || state.game.gameLost) return;

    if (trickArray.some((playedCard) => playedCard === "")) return;

    //lets calculate the score
    let trickWinner = tricks.getWinningCard();
    tricks.addTrick();
    dispatch(ADD_WINNERS(trickWinner));
    let score = [...state.game.score];
    score[trickWinner.player] += 1;
    dispatch(SET_SCORE(score));

    dispatch(REMOVE_PLAYED_CARDS());

    //update target
    dispatch(UPDATE_TARGET(trickWinner.player));

    let winnersScore = 0;
    let winnersArray = [...state.game.winners, trickWinner];
    winnersArray.forEach((w) => {
      if (trickWinner.player == w.player) winnersScore++;
    });

    // we have to start the animation
    if (state.game.stopAnimation) {
      dispatch(SET_STOP_ANIMATION(false));
    }

    // if animation set to false , add animation
    if (!state.game.isAnimationNeed) {
      dispatch(SET_ANIMATION(true));
    }

    // for resetting the animation
    if (state.game.infiniteAnimation) dispatch(SET_INFINITE_ANIMATION(false));

    if (winnersScore > target[trickWinner.player]) {
      dispatch(
        SET_TOTAL_SCORE([
          ...state.game.totalScore,
          new Array(state.game.playerCount).fill(""),
        ])
      );

      // we need to reduce the attempts only for real game
      if (!isGuideNeed && !gamePractice) {
        if (state.game.attempts - 1 === 0) {
          realGameEvents("end", "realGame_lost")
          saveStats(state.game.gameWon, state.game.attempts, allAttempts);
        }
        dispatch(UPDATE_ATTEMPTS(state.game.attempts - 1));
      }

      if(gamePractice) {
        practiceEvents("end", "retry")
      }

      dispatch(SET_GAME_LOST(true));
      dispatch(SET_PREV_TRICK(state.game.tricks));
      return;
    }

    if (state.game.tricks.length < state.game.cardCount) {
      let newTricks = [...state.game.tricks];
      newTricks.push(new Array(state.game.playerCount).fill(""));
      dispatch(SET_TRICKS(newTricks));

      if(gamePractice){
        practiceEvents("mid", fullTricks.length)
      }
      realGameEvents("mid", fullTricks.length)
      //increase the currentTrick
      dispatch(SET_CURRENT_TRICK(state.game.currentTrick + 1));

      //Empty the trickArray
      setTrickArray(new Array(state.game.playerCount).fill(""));

      //change the next turn to winner of current trick
      dispatch(SET_CURRENT_TURN(trickWinner.player));
    }

    //To check the game won or Lost
    if (state.game.tricks.length === state.game.cardCount) {
      let gameWon = true;
      score.forEach((s, idx) => {
        if (s != target[idx]) gameWon = false;
      });

      dispatch(SET_TOTAL_SCORE([...state.game.totalScore, score]));
      if (state.game.attempts === 1 && !gameWon) {
        // we need to reduce the attempts only for real game
        if (!isGuideNeed && !gamePractice) {
          dispatch(UPDATE_ATTEMPTS(state.game.attempts - 1));
          saveStats(state.game.gameWon, state.game.attempts, allAttempts);
        }
        dispatch(SET_GAME_LOST(true));
        dispatch(SET_PREV_TRICK(state.game.tricks));
        return;
      }

      if (gameWon) {
        practiceEvents("end", "successfully_completed")
        dispatch(SET_GAME_WON(true));
        dispatch(SET_PREV_TRICK(state.game.tricks));
        if (!isGuideNeed && !gamePractice) {
          realGameEvents("end", "realGame_won")
          saveStats(true, state.game.attempts, allAttempts);
        }
      } else {
        // we need to reduce the attempts only for real game
        if (!isGuideNeed && !gamePractice)
          dispatch(UPDATE_ATTEMPTS(state.game.attempts - 1));
        dispatch(SET_GAME_LOST(true));
        dispatch(SET_PREV_TRICK(state.game.tricks));
      }
    }
  };

  const undo = () => {
    if (state.game.gameLost || state.game.gameWon) return;

    if (trickArray.length === 0) return;

    // if(alertStop) {
    // }

    let oldTrickArray = [...trickArray];

    oldTrickArray.forEach((playedCard) => {
      if (playedCard !== "") tricks.undo();
    });

    // for resetting the animation
    if (state.game.infiniteAnimation) {
      dispatch(SET_INFINITE_ANIMATION(false));
      dispatch(SET_INFINITE_ANIMATED_TARGET());
    }

    setTrickArray(new Array(state.game.playerCount).fill(""));

    //changing the isPlayed card flag to true
    oldTrickArray.forEach((playedCard) => {
      if (playedCard !== "")
        dispatch(
          SET_IS_PLAYED({
            card: playedCard.card,
            player: playedCard.player,
            isPlayed: false,
          })
        );
    });

    //to remove the cards from tricksArray
    dispatch(UNDO_CARD());
  };

  //we have to reset everything
  const reset = () => {
    if (!state.game.isAnimationComplete) return;
    if (!state.game.isGuideNeed && state.game.gameWon && !gamePractice) return;

    if (
      !state.game.isGuideNeed &&
      !state.game.gamePractice &&
      state.game.score.every((s) => s == 0)
    ) {
      return;
    }

    if (state.game.attempts === 0) return;

    if (
      !state.game.gameLost &&
      !state.game.gameWon &&
      !isGuideNeed &&
      !gamePractice
    ) {
      dispatch(
        SET_TOTAL_SCORE([
          ...state.game.totalScore,
          new Array(state.game.playerCount).fill(""),
        ])
      );
      let attempts = state.game.attempts;
      if (attempts - 1 === 0) {
        dispatch(SET_GAME_LOST(true));
        saveStats(false, state.game.attempts, allAttempts);
        dispatch(GAME_LOCK());
        dispatch(UPDATE_ATTEMPTS(state.game.attempts - 1));
        return;
      }
      dispatch(UPDATE_ATTEMPTS(state.game.attempts - 1));
    }

    // reset everything except the attempts to initial state
    deck = new Deck(gameJSON.ranks, gameJSON.suits);
    tricks = new Tricks(deck);
    tricks.trump.setTrump = "T";

    if (isGuideNeed) {
      tricks.maxLength.setMaxLength = tutorialProblems[index].hands.length;
      setFailureContainerOpen(false);
      dispatch(SET_HANDS(tutorialProblems[index].hands));
      dispatch(SET_TARGET(tutorialProblems[index].target));
      dispatch(CHANGE_CARD_COUNT(tutorialProblems[index].hands[0].length));
      dispatch(CHANGE_PLAYER_COUNT(tutorialProblems[index].hands.length));
      dispatch(SET_INTRO_FLAG(true));
      dispatch(SET_BALL_COUNT(tutorialProblems[index].hands[0].length));
      dispatch(GAME_LOCK());
      setTrickArray(new Array(tutorialProblems[index].hands.length).fill(""));
      dispatch(
        SET_TRICKS([new Array(tutorialProblems[index].hands.length).fill("")])
      );
      dispatch(
        SET_SCORE(new Array(tutorialProblems[index].hands.length).fill(0))
      );
    } else if (gamePractice) {
      tricks.maxLength.setMaxLength =
        practiceProblems[showProblems].hands.length;
      setFailureContainerOpen(false);
      dispatch(SET_HANDS(practiceProblems[showProblems].hands));
      dispatch(SET_TARGET(practiceProblems[showProblems].target));
      dispatch(
        CHANGE_CARD_COUNT(practiceProblems[showProblems].hands[0].length)
      );
      dispatch(
        CHANGE_PLAYER_COUNT(practiceProblems[showProblems].hands.length)
      );
      dispatch(SET_BALL_COUNT(practiceProblems[showProblems].hands[0].length));
      dispatch(GAME_UNLOCK());
      setTrickArray(
        new Array(practiceProblems[showProblems].hands.length).fill("")
      );
      dispatch(
        SET_TRICKS([
          new Array(practiceProblems[showProblems].hands.length).fill(""),
        ])
      );
      dispatch(
        SET_SCORE(
          new Array(practiceProblems[showProblems].hands.length).fill(0)
        )
      );
    } else {
      tricks.maxLength.setMaxLength = todaysProblem.hands.length;
      setFailureContainerOpen(false);
      dispatch(SET_HANDS(todaysProblem.hands));
      dispatch(SET_TARGET(todaysProblem.target));
      dispatch(CHANGE_CARD_COUNT(todaysProblem.hands[0].length));
      dispatch(CHANGE_PLAYER_COUNT(todaysProblem.hands.length));
      dispatch(SET_BALL_COUNT(todaysProblem.hands[0].length));
      dispatch(GAME_UNLOCK());
      setTrickArray(new Array(todaysProblem.hands.length).fill(""));
      dispatch(SET_TRICKS([new Array(todaysProblem.hands.length).fill("")]));
      dispatch(SET_SCORE(new Array(todaysProblem.hands.length).fill(0)));
    }

    // for animation
    dispatch(SET_ANIMATED_TARGET());
    dispatch(SET_INFINITE_ANIMATED_TARGET());
    dispatch(SET_CURRENT_TRICK(1));
    dispatch(SET_CURRENT_TURN(0));
    dispatch(RESET_WINNERS());
    dispatch(SET_FOLLOWSUIT(""));
    dispatch(SET_ANIMATION(false));
    dispatch(SET_STOP_ANIMATION(true));
    dispatch(SET_FADE_IN(false));
    setFailureContainerOpen(false);
    dispatch(SET_INFINITE_ANIMATION(false));
    if (state.game.gameLost) {
      dispatch(SET_GAME_LOST(false));
    } else {
      dispatch(SET_GAME_WON(false));
    }
  };

  const handleShareStatus = async () => {
    await shareStatus(
      pad(dayIndex + 1, 2),
      todaysProblem.target,
      state.game.totalScore,
      state.game.winners,
      state.game.playerCount,
      state.game.cardCount,
      state.game.isDarkMode,
      hardMode,
      () => setAlertMessage(success, "Copied results to clipboard"),
      () => setAlertMessage(error, "Results not Copied!")
    );
  };

  const handleHelp = () => {
    if (!state.game.isAnimationComplete) return;
    // to stop ongoing animation
    dispatch(SET_STOP_ANIMATION(true));

    dispatch(SET_ANIMATION(false));

    if (state.game.isGuideNeed && !showTutoModal) {
      if (state.game.lock) {
        dispatch(GAME_UNLOCK());
      }
      dispatch(DISABLE_GUIDE());
      dispatch(SET_INTRO_FLAG(false));

      if (isFailureContainerOpen) {
        setFailureContainerOpen(false);
      }
      // to load the real game
      setGameLoaded(false);
    } else {
      setIsGamePractice(false);
      dispatch(SET_PRACTICE_SELECTION(false));
      dispatch(SET_GAME_PRACTICE(false));
      if (state.game.tricks[0].some((card) => card !== "")) {
        dispatch(ENABLE_GUIDE());
        dispatch(SET_INTRO_FLAG(true));
        dispatch(GAME_LOCK());
        // to load the tutorial game
        setGameLoaded(false);
        if (isFailureContainerOpen) {
          setFailureContainerOpen(false);
        }
        return;
      }
      dispatch(ENABLE_GUIDE());
      dispatch(SET_INTRO_FLAG(true));
      dispatch(GAME_LOCK());

      // to load the tutorial game
      setGameLoaded(false);

      if (isFailureContainerOpen) {
        setFailureContainerOpen(false);
      }
    }
  };

  const handlePractice = () => {
    if (!state.game.isAnimationComplete) return;

    // to stop ongoing animation
    dispatch(SET_STOP_ANIMATION(true));
    dispatch(GAME_UNLOCK());
    dispatch(DISABLE_GUIDE());

    dispatch(SET_ANIMATION(false));

    setIsGamePractice(true);
    dispatch(SET_GAME_PRACTICE(true));
    dispatch(SET_INTRO_FLAG(false));
    dispatch(SHOW_PROBLEMS(null));

    if (isGamePractice) {
      setIsGamePractice(false);
      dispatch(SET_GAME_PRACTICE(false));

      if (isFailureContainerOpen) {
        setFailureContainerOpen(false);
      }

      dispatch(SET_PRACTICE_SELECTION(false));
      setGameLoaded(false);
    } else {
      dispatch(SET_PRACTICE_SELECTION(true));
      dispatch(SET_GAME_PRACTICE(true));
      if (isFailureContainerOpen) {
        setFailureContainerOpen(false);
      }
      return;
    }

    if (isFailureContainerOpen) {
      setFailureContainerOpen(false);
    }
  };

  const goPrev = () => {
    dispatch(SET_PRACTICE_SELECTION(true));
    dispatch(SHOW_PROBLEMS(null));
    reset();
  };

  return (
    <>
      <NavBar
        setIsSettingModalOpen={setIsSettingModalOpen}
        setIsInfoModalOpen={setIsInfoModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
        setIsHintModalOpen={setIsHintModalOpen}
        setIsSolutionModalOpen={setIsSolutionModalOpen}
        setHardMode={setHardMode}
        handleHelp={handleHelp}
        handlePractice={handlePractice}
        buttonClick={buttonClick}
      />
      <Alert />

      {/* If gamePractice true, it'll show problems in Practice Cardle screen... */}
      {gamePractice ? (
        showProblems !== null ? (
          <></>
        ) : (
          <div className="practice-page">
            <PracticeCardle
              practiceCardleProblems={practiceCardleProblems}
              practiceEvents={practiceEvents}
            />
          </div>
        )
      ) : (
        <></>
      )}

      {/* Lives container */}
      {gamePractice ? (
        showProblems !== null ? (
          // SHOWING RESET and BACK BUTTON (replace of lives container)
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              height: "19vh",
            }}
          >
            <div
              style={{
                margin: "auto",
                marginTop:
                  deviceSize < 550
                    ? helpIntroFlag || isGuideNeed
                      ? "calc(var(--card-gap) * 3)"
                      : "7vh"
                    : isGuideNeed || helpIntroFlag
                    ? "5vh"
                    : "9vh",

                width:
                  "calc(calc(var(--card-size) * var(--player-count)) + (var(--card-gap) * (var(--player-count) + 1) * 2.5 ))",
              }}
            >
              {/* For not showing lives container in gamePractice */}
              {gamePractice ? (
                showProblems !== null ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={
                        gameWon ||
                        (attempts > 0 && (tricks.length > 1 || gameLost))
                          ? "lives1 retry"
                          : "lives2"
                      }
                      style={{
                        ...practiceReset,
                      }}
                      onClick={() => {
                        reset();
                        buttonClick("practice", "reset");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowsRotate}
                        color={isDarkMode ? "#fbfbfb" : "var(--dark-container)"}
                      />
                    </div>
                    <div
                      style={{
                        ...practiceReset,
                        cursor: "pointer",
                        pointerEvents: !state.game.isAnimationComplete
                          ? "none"
                          : "auto",
                      }}
                      className="retry"
                      onClick={() => {
                        goPrev();
                        buttonClick("practice", "back");
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faArrowLeftLong}
                        color={isDarkMode ? "#fbfbfb" : "var(--dark-container)"}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : null}
            </div>
          </div>
        ) : null
      ) : (
        // This is the one showed in actual today's cardle:
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              margin: "auto",
              marginTop:
                deviceSize < 550
                  ? helpIntroFlag || isGuideNeed
                    ? "calc(var(--card-gap) * 3)"
                    : "7vh"
                  : isGuideNeed || helpIntroFlag
                  ? "5vh"
                  : "9vh",

              width:
                "calc(calc(var(--card-size) * var(--player-count) ) + (var(--card-gap) * ( var(--player-count) + 1 ) * 2.5))",
            }}
          >
            {state.game.isGuideNeed || gamePractice ? null : (
              <SideGrid
                reset={reset}
                buttonClick={buttonClick}
                buttonVisible={isFailureContainerOpen}
              />
            )}
          </div>
        </div>
      )}

      {/* Walkthrough */}
      {!gamePractice ? (
        <div
          style={{
            lineHeight: "1.75",
            margin: "auto",
            marginTop: isGuideNeed ? (deviceSize < 550 ? "0" : "0vh") : "",
            marginBottom:
              state.game.gameLost || state.game.gameWon ? "3vh" : "2vh",
          }}
        >
          {state.game.isGuideNeed ? (
            !state.game.helpIntroFlag ? (
              <Walkthrough
                reset={reset}
                loadTodaysCardle={loadTodaysCardle}
                nextTutorialProblem={nextTutorialProblem}
                prevTutorialProblem={prevTutorialProblem}
                isTouchEnabled={isTouchEnabled}
                handlePractice={handlePractice}
                setShowTutoModal={setShowTutoModal}
                buttonClick={buttonClick}
              />
            ) : null
          ) : (
            <div className="guide-null"></div>
          )}
        </div>
      ) : (
        <></>
      )}

      {/* --------------- WHOLE-CONTAINER FLEX --------------- */}
      {gamePractice ? (
        showProblems !== null ? (
          // In practice Cardle
          <div
            className="whole-container-flex"
            style={{
              bottom:
                deviceSize < 550
                  ? helpIntroFlag || isGuideNeed
                    ? "calc(var(--card-gap) * 7)"
                    : "7vh"
                  : isGuideNeed || helpIntroFlag
                  ? "3vh"
                  : "9vh",
              gap: helpIntroFlag
                ? state.game.deviceSize < 550
                  ? "calc(var(--card-gap) * 3)"
                  : "calc(var(--card-gap) * 7)"
                : "",
            }}
          >
            <div>
              {!state.game.helpIntroFlag ? (
                <Target buttonVisible={isFailureContainerOpen} />
              ) : null}

              <Trick
                onEnter={enter}
                trickArray={trickArray}
                onUndo={undo}
                buttonVisible={isFailureContainerOpen}
                gameLoaded={gameLoaded}
                buttonClick={buttonClick}
                category={"practice"}
              />
            </div>

            {/* This is for winning or failure container */}
            {isFailureContainerOpen ? (
              <div
                className="failure-container"
                style={{
                  pointerEvents:
                    state.game.gameLost && state.game.attempts === 0
                      ? "none"
                      : state.game.gameWon
                      ? "none"
                      : "auto",
                  boxShadow: !isDarkMode
                    ? !gameWon
                      ? attempts > 0
                        ? "1px 2px 4px"
                        : ""
                      : ""
                    : gameLost
                    ? "0px 1px 2.5px var(--button-shadow)"
                    : "",
                  ...(state.game.isDarkMode
                    ? {
                        backgroundColor: "var(--dark-container)",
                        color: "#fbfbfb",
                        textAlign: "center",
                      }
                    : {
                        backgroundColor: "var(--light-container)",
                        color: "rgb(45,45,45,70%)",
                      }),
                  ...langFont.containerButton,
                }}
              >
                {state.game.gameWon ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "var(--card-gap)",
                        ...langFont.containerButton,
                      }}
                    >
                      <div
                        style={{
                          fontSize: "calc(var(--card-size) * 0.36)",
                          fontWeight: "500",
                          fontFamily: "satoshi-light",
                        }}
                      >
                        {t("buttons.success")}{" "}
                      </div>
                    </div>
                  </>
                ) : state.game.attempts > 0 ? ( // For tryAgain
                  <div
                    style={{ margin: "100% 0%" }}
                    onClick={() => {
                      reset();
                      buttonClick("practice", "reset");
                    }}
                  >
                    <div
                      className="failure-refresh"
                      style={{ ...langFont.containerButton }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ fontSize: "calc(var(--card-size) * 0.36)" }}
                          icon={faArrowsRotate}
                        />
                      </div>
                      <div
                        className="failure-msg"
                        style={{
                          // fontSize: "calc(var(--card-size) * 0.36)",
                          fontWeight: "500",
                          fontFamily: "satoshi-light",
                          ...langFont.containerButton,
                        }}
                      >
                        {t("buttons.tryAgain")}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}

            <div>
              <div
                style={{
                  opacity: state.game.lock
                    ? isHandHovered
                      ? "0.5"
                      : "1"
                    : "1",
                }}
                onMouseEnter={() => {
                  setHandHovered(true);
                }}
                onMouseLeave={() => {
                  setHandHovered(false);
                }}
                className="grid-hand"
              >
                <HandGrid onPlay={onPlay} trump={trump} />
              </div>
            </div>

            <div
              style={{
                fontFamily: "satoshi-light",
                fontWeight: "100",
                position: "fixed",
                bottom: deviceSize < 550 ? "1vh" : "2vh",
                opacity: "0.5",
                left: "50%",
                transform: "translate(-50%, 0)",
                ...langFont.stats,
              }}
            >
              {" "}
              {t("labels.practiceWithCount", {
                count: `${pad(showProblems + 1, 2)}`,
              })}
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        // In Today's Cardle
        <div
          className="whole-container-flex"
          style={{
            top: helpIntroFlag ? (deviceSize < 550 ? "58%" : "64%") : "",
            transform: helpIntroFlag
              ? deviceSize < 550
                ? "translate(0, -58%)"
                : "translate(0, -62%)"
              : "",
            bottom:
              deviceSize < 550
                ? helpIntroFlag || isGuideNeed
                  ? "2%"
                  : "7vh"
                : isGuideNeed
                ? helpIntroFlag
                  ? ""
                  : "3vh"
                : "9vh",
            gap: helpIntroFlag
              ? state.game.deviceSize < 550
                ? "calc(var(--card-gap) * 3)"
                : "calc(var(--card-gap) * 7)"
              : "",
          }}
        >
          {/* Show trick or not when Intro Flag */}
          {!state.game.helpIntroFlag ? (
            <div>
              {!state.game.helpIntroFlag ? (
                <Target buttonVisible={isFailureContainerOpen} />
              ) : null}
              <Trick
                onEnter={enter}
                trickArray={trickArray}
                onUndo={undo}
                buttonVisible={isFailureContainerOpen}
                gameLoaded={gameLoaded}
                buttonClick={buttonClick}
                category={isGuideNeed ? "tutorial" : "real_game"}
              />
            </div>
          ) : (
            <div>
              <Walkthrough
                trump={trump}
                isHandHovered={isHandHovered}
                reset={reset}
                loadTodaysCardle={loadTodaysCardle}
                nextTutorialProblem={nextTutorialProblem}
                prevTutorialProblem={prevTutorialProblem}
                isTouchEnabled={isTouchEnabled}
                handlePractice={handlePractice}
                setShowTutoModal={setShowTutoModal}
                buttonClick={buttonClick}
              />
            </div>
          )}

          {isFailureContainerOpen ? (
            <div
              className="failure-container"
              style={{
                boxShadow: !isDarkMode
                  ? !gameWon
                    ? attempts > 0
                      ? "1px 2px 4px"
                      : ""
                    : isGuideNeed
                    ? "none"
                    : "1px 2px 4px"
                  : !gameWon
                  ? attempts > 0
                    ? "0px 2px 2.5px var(--button-shadow)"
                    : ""
                  : isGuideNeed
                  ? "none"
                  : "0px 1px 2.5px var(--button-shadow)",

                pointerEvents:
                  state.game.gameLost && state.game.attempts === 0
                    ? "none"
                    : isGuideNeed
                    ? "none"
                    : "auto",

                ...(state.game.isDarkMode
                  ? {
                      backgroundColor: "var(--dark-container)",
                      color: "#fbfbfb",
                      textAlign: "center",
                    }
                  : {
                      backgroundColor: "var(--light-container)",
                      color: "rgb(45,45,45,70%)",
                    }),
              }}
            >
              {state.game.gameWon ? (
                <div
                  style={{
                    display: "flex",
                    gap: "var(--card-gap)",
                    pointerEvents: isGuideNeed ? "none" : "auto",
                  }}
                  onClick={handleShareStatus}
                >
                  <div
                    style={{
                      // fontSize: "calc(var(--card-size) * 0.36)",
                      fontWeight: "700",
                      pointerEvents: isGuideNeed ? "none" : "auto",
                      fontFamily: "satoshi-light",
                      color: state.game.isDarkMode
                        ? "var(--white)"
                        : "var(--font-color-tuto)",
                      ...langFont.containerButton,
                    }}
                  >
                    {isGuideNeed
                      ? t("buttons.tutorialOver")
                      : t("buttons.success")}
                  </div>
                  {isGuideNeed ? null : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {isGuideNeed ? null : (
                        <FontAwesomeIcon
                          style={{
                            fontSize: "calc(var(--card-size) * 0.36)",
                          }}
                          icon={faShareAlt}
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : state.game.attempts > 0 ? (
                <div
                  style={{ margin: "100% 0%" }}
                  onClick={() => {
                    reset();
                    buttonClick("real_game", "reset");
                  }}
                >
                  <div
                    className="failure-refresh"
                    style={{ ...langFont.containerButton }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: "calc(var(--card-size) * 0.36)" }}
                        icon={faArrowsRotate}
                      />
                    </div>
                    <div
                      className="failure-msg"
                      style={{
                        // fontSize: "calc(var(--card-size) * 0.36)",
                        fontWeight: "500",
                      }}
                    >
                      {t("buttons.tryAgain")}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: "var(--card-gap)",
                    ...langFont.containerButton,
                  }}
                >
                  <div
                    style={{
                      pointerEvents: "none",
                      // fontSize: "calc(var(--card-size) * 0.36)",
                      fontWeight: "500",
                    }}
                  >
                    {t("buttons.tryTomorrow")}{" "}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {/* Hand grid with Edition container --- don't know how it works */}
          <div>
            <div
              style={{
                opacity: state.game.lock ? (isHandHovered ? "0.5" : "1") : "1",
              }}
              onMouseEnter={() => {
                setHandHovered(true);
                // !isTouchEnabled ?
                // setHandHovered(true) : <></>
              }}
              onMouseLeave={() => {
                setHandHovered(false);
                // !isTouchEnabled ? setHandHovered(false) : <></>;hand-item-grid
              }}
              className="grid-hand"
            >
              <HandGrid onPlay={onPlay} trump={trump} />
            </div>
            {isGuideNeed ? null : realProblems && dayIndex !== null ? (
              <div
                style={{
                  fontFamily: "satoshi-light",
                  fontWeight: "100",
                  position: "fixed",
                  bottom: deviceSize < 550 ? "1vh" : "2vh",
                  opacity: "0.5",
                  left: "50%",
                  transform: "translate(-50%, 0)",
                  ...langFont.stats,
                }}
              >
                {" "}
                {t("labels.edition", { count: `${pad(dayIndex + 1, 2)}` })}
              </div>
            ) : null}
          </div>
        </div>
      )}

      <NavModal
        handleHelp={handleHelp}
        todaysProblem={todaysProblem}
        isInfoModalOpen={isInfoModalOpen}
        isSettingModalOpen={isSettingModalOpen}
        isShareModalOpen={isShareModalOpen}
        isHintModalOpen={isHintModalOpen}
        showTargetModal={showTargetModal}
        showTutoModal={showTutoModal}
        buttonClick={buttonClick}
        handleCloseTutorial={() => {
          setShowTutoModal(false);
        }}
        isSolutionModalOpen={isSolutionModalOpen}
        handleCloseInfo={() => {
          setIsInfoModalOpen(false);
        }}
        handleCloseSettings={() => {
          setIsSettingModalOpen(false);
        }}
        handleCloseShare={() => {
          setIsShareModalOpen(false);
        }}
        handleCloseTarget={() => {
          setShowTargetModal(false);
        }}
        handleCloseHint={() => {
          setIsHintModalOpen(false);
        }}
        closeSolutionModal={() => {
          setIsSolutionModalOpen(false);
        }}
        handleShareStatus={handleShareStatus}
        reset={reset}
        solution={
          isGuideNeed
            ? tutorialProblems[index].solution
            : realProblems && dayIndex !== null && !gamePractice
            ? dayIndex === 0
              ? realProblems[realProblems.length - 1].solution
              : realProblems[dayIndex - 1].solution
            : gamePractice
            ? showProblems !== null
              ? practiceProblems[showProblems].solution
              : null
            : null
        }
        linesOfPlay={
          isGuideNeed
            ? tutorialProblems[index].linesOfPlay
            : realProblems && dayIndex !== null && !gamePractice
            ? dayIndex === 0
              ? realProblems[realProblems.length - 1].linesOfPlay
              : realProblems[dayIndex - 1].linesOfPlay
            : gamePractice
            ? showProblems !== null
              ? practiceProblems[showProblems].linesOfPlay
              : null
            : null
        }
        trump={
          isGuideNeed
            ? tutorialProblems[index].trump
            : realProblems && dayIndex !== null && !gamePractice
            ? dayIndex === 0
              ? realProblems[realProblems.length - 1].trump
              : realProblems[dayIndex - 1].trump
            : gamePractice
            ? showProblems !== null
              ? practiceProblems[showProblems].trump
              : null
            : null
        }
        hands={
          isGuideNeed
            ? tutorialProblems[index].hands
            : realProblems && dayIndex !== null && !gamePractice
            ? dayIndex === 0
              ? realProblems[realProblems.length - 1].hands
              : realProblems[dayIndex - 1].hands
            : gamePractice
            ? showProblems !== null
              ? practiceProblems[showProblems].hands
              : null
            : null
        }
        target={
          isGuideNeed
            ? tutorialProblems[index].target
            : realProblems && dayIndex !== null && !gamePractice
            ? dayIndex === 0
              ? realProblems[realProblems.length - 1].target
              : realProblems[dayIndex - 1].target
            : gamePractice
            ? showProblems !== null
              ? practiceProblems[showProblems].target
              : null
            : null
        }
      />
    </>
  );
};

export const darkBgColor = {
  body: "rgb(51,51,51)",
  containers: "rgb(37,37,38)",
};

export { tricks, trump, ourTrumpColor, cardSize, tomorrow };
export default Home;
